<template>
  <div>

  <v-card width="370" class="mx-auto mt-5" flat>
      <Errors v-bind:errors="errors"/>
    <v-card-title>
      <h4>Passwort zurücksetzen</h4>
    </v-card-title>
    <div v-if="!showSuccess">
      <v-card-text>
        <p>Gib deine E-Mail Adresse ein, um dein Passwort zurückzusetzen.</p>
        <v-form @submit.prevent>
          <v-text-field 
          type="email" 
          label="E-Mail"
          v-model.trim="email"
          :rules="emailRules"
          required
          />
        </v-form>

      </v-card-text>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <v-btn 
        color="error" 
        depressed  
        @click="resetPassword()"
        >
        Zurücksetzen
        </v-btn>
      </v-card-actions>
      <v-card-text><router-link to="/login">Zurück zum Login.</router-link></v-card-text>
    </div>
    <div v-else>
      <v-card-text>
        Überprüfe deine E-Mails! Nachdem du das Passwort zurückgesetzt hast, gehe zurück zum <router-link to="/login">Login</router-link>.
      </v-card-text>
    </div>
    
  </v-card>

  </div>
  
</template>

<script>
import { auth } from '@/firebase';
import Errors from '@/components/Errors.vue';

export default {

  components: {
    Errors,
  },

  data() {
    return {
      email: '',
      showSuccess: false,
      errors: [],
      valid: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  methods: {
    async resetPassword() {
        
        try {
          await auth.sendPasswordResetEmail(this.email);
          this.showSuccess = true;
        } catch (error) {
          console.log("Error sending Email: ", error.message);
          this.errors.push({
            text: 'Die konnte nicht gesendet werden.', 
            type:'firestore',
          });
          window.scrollTo(0,0);
        }
    }
  }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>