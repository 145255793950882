<template>
  <div>
    <!-- Dicides how to display the events depending on their type: event, online, empty, break, divider -->

    <!-- for type event, online and empty, start and end time is shown left of the event  -->
    <v-simple-table v-if="event.type == 'event' || event.type == 'empty'|| event.type == 'online'" class="mx-2 my-3">
        <table>
            <tr>
                <td style="text-align: right; vertical-align:top;"> 
                    <Time v-bind:time="event.start" />
                </td>
                <td rowspan="2" width="100%">
                    <EventRaumplan v-if="event.type == 'event'" v-bind:event="event" />
                    <EventRaumplan v-if="event.type == 'online'" v-bind:event="event" />
                    <EmptyEvent v-if="event.type == 'empty'" v-bind:event="event" />
                </td>
            </tr>
            <tr>
                <td style="text-align: right; vertical-align:bottom;">
                    <Time v-bind:time="event.end" />
                </td>
            </tr>
        </table>
    </v-simple-table>

    <!-- for type break and divider, the full width is used -->
    <Break v-if="event.type == 'pause'" v-bind:event="event" />

  </div>
</template>

<script>
import EventRaumplan from '@/components/Raumplan/EventRaumplan';
import Time from '@/components/Raumplan/Time';
import EmptyEvent from '@/components/Raumplan/EmptyEvent.vue';
import Break from '@/components/Raumplan/Break.vue';

export default {
    name: 'Event',
    props: ["event", "duration"],
    components: {
        EventRaumplan,
        Time,
        EmptyEvent,
        Break,
    },
    data() {
        return {

        }
    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>