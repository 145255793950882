<template>

<div>
  <div class="mr-2">
    <div class="font-weight-bold mb-n1"> {{HoursAndMinList[0]}} </div>
    <div> {{HoursAndMinList[1]}} </div>
  </div>
  
</div>
  
</template>

<script>
export default {
    name: 'Time',
    props: ["time"],
    computed: {
        HoursAndMinList () {
            return this.time.split(':')
        }
    }
}
</script>

<style scoped>
</style>