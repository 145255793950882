<template>
  <div>

    <v-card
    color="accent"
    outlined
    class="ma-3"
    @click="redirectToEditTeacher"
    >
      <v-card-title class="text-body-1 font-weight-medium my-n2">
        {{teacher.firstname}} {{teacher.lastname}}
      </v-card-title>
    </v-card>

  </div>
</template>

<script>


export default {
    name: 'TeacherComp',

    props: ["teacher"],

    data() {
        return {

        }
    },
    methods: {
        redirectToEditTeacher() {
          /* redirect to EditTeacher view and pass with it the id of the teacher */
          this.$router.push('/teacher/' + this.teacher.id);
        }
    }
}
</script>

<style>

</style>