<template>
<div>
  <v-card max-width="370" class="mx-auto mt-5" flat>
    <Errors v-bind:errors="errors"/>
    <v-card-title>
      Nachhilfeschüler:innen
      <v-spacer></v-spacer>
      <router-link to="/addstudent" v-if="isAdmin" style="text-decoration: none;">
        <v-icon color="primary">mdi-plus</v-icon>
      </router-link>
    </v-card-title>
    
    <!-- search bar -->
    <v-text-field
      v-model.trim="searchInput"
      hide-details
      prepend-inner-icon="mdi-magnify"
      single-line
      clearable
      class="mx-4 mb-4"
    ></v-text-field>

    <div flat 
    v-for="student in filteredStudents" 
    v-bind:key="student.id"
    >
      <StudentComp v-bind:student="student"/>
    </div>

  </v-card>

</div>
</template>


<script>
import { mapState } from 'vuex';
import { auth, studentsCollection } from '../firebase';
import StudentComp from '../components/StudentComp';
import Errors from '@/components/Errors.vue';

export default {
  name: 'Students',

  components: {
    StudentComp,
    Errors
  },

  data() {
    return {
      students: [],
      errors: [],
      searchInput: '',
    }
  },

  computed: {
    ...mapState(['isAdmin']),

    filteredStudents() {
      return this.students.filter(student => {
        let isInFirstName = student.firstname.toLowerCase().includes(this.searchInput.toLowerCase());
        let isInLastName = student.lastname.toLowerCase().includes(this.searchInput.toLowerCase());

        return isInFirstName || isInLastName
      })
    }
  },

  async created() {
    this.getStudents();
  },

  methods: {
    async getStudents() {
      //Get the institueId of the currently logged in user
      let instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

      studentsCollection
        .where('instituteId', '==', instituteId)
        .orderBy('firstname')
        .get()
        .then(snapshot => {
          this.students = [];
          snapshot.forEach(doc => {
            let student = doc.data();
            student.id = doc.id;
            this.students.push(student);
          })
        }).catch((error) => {
          console.log("Error getting documents: ", error);
          this.errors.push({
            text: 'Die Schüler:innen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
        });
    },

  }
}
</script>

<style>

</style>