<template>
  <div>

    <v-card
    color="accent"
    outlined
    class="ma-3"
    @click="redirectToEditStudent"
    >
      <v-card-title class="my-n3">
        <span class="text-body-1 font-italic font-weight-medium">{{student.firstname}} {{student.lastname}}</span>
        <v-spacer/>
        <BalanceLessonAccount v-bind:balance="this.student.balanceLessonAccount"/>
      </v-card-title>
      <!-- <v-card-subtitle class="text-caption my-n3">
        {{student.info}}
      </v-card-subtitle>
     -->
    </v-card>

  </div>
</template>

<script>
import BalanceLessonAccount from '@/components/BalanceLessonAccount.vue';

export default {
    name: 'Student',

    props: ["student"],

    components: {
      BalanceLessonAccount,
    },

    data() {
        return {

        }
    },

    created() {
    },

    methods: {
        redirectToEditStudent() {
          /* redirect to EditStudent view and pass with it the id of the student */
          this.$router.push('/student/' + this.student.id);
        }
    }
}
</script>

<style scoped>

</style>