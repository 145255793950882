<template>
  <v-card flat>
    <v-divider></v-divider>
    <v-list dense rounded>
        <v-list-item-group v-model="selectedRoomIndex" color="primary" >
              <v-list-item 
              class="my-n1" 
              v-for="(room, i) in rooms"
              :key="i"
              >
              {{room.name}}
              </v-list-item>
        </v-list-item-group>  
     
    </v-list>
    <v-divider class="mb-8"></v-divider>
  </v-card>   
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'SelectRoom',
    props: ['roomIndex', 'rooms'],
    data() {
        return {
            selectedRoomIndex: this.roomIndex,
        }
    },
    watch: {
        selectedRoomIndex: function(value) {
            /* changes the room in the parent component */
            this.$emit('changeRoom', value);
        }
    },
    methods: {
    }
}
</script>

<style scoped>

</style>