<template>
<div>
    <v-card
    outlined
    height="5em"
    @click="redirectToPrefilledAddEventForm"
    >
    </v-card>

</div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'EmptyEvent',
    props: ["event"],
    data() {
        return {
        }
    },
    computed: {
      ...mapState(['userProfile']),
    },
    methods: {
      redirectToPrefilledAddEventForm(){

        /* add date, time and room to store */
        this.$store.commit('setprefillAddEvent', {key: 'date', value: this.event.date});
        this.$store.commit('setprefillAddEvent', {key: 'start', value: this.event.start});
        this.$store.commit('setprefillAddEvent', {key: 'end', value: this.event.end});
        this.$store.commit('setprefillAddEvent', {key: 'room', value: {'name': this.event.room, 'id': this.event.roomId}});

        /* redirect to addEvent view */
        this.$router.push('/addevent');
      }
    }
}
</script>

<style>

</style>