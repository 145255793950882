<template>
  <div>

    <v-card width="370" class="mx-auto mt-5" flat>
      <Errors v-bind:errors="authErrors"/>
    <v-card-title>
      <h4>Log In</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent v-model="valid">
        <v-text-field 
        type="email" 
        label="E-Mail"
        autocomplete="email"
        v-model.trim="loginForm.email"
        :rules="emailRules"
        required
        />
        <v-text-field 
        v-model.trim="loginForm.password"
        :type="showPassword ? 'text' : 'password'"
        label="Passwort"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[v => !!v || 'Gib ein Passwort an.']"
        @click:append="showPassword = !showPassword"
        required
        />
      </v-form>
    </v-card-text>

    <v-card-actions class="mx-2">
      <v-btn 
      color="accent"
      depressed 
      block
      style="width: 130px;" 
      :disabled="!valid"
      @click="login()"
      >
      Login
      </v-btn>
    </v-card-actions>
    <v-card-text><router-link to="/resetpassword">Password vergessen?</router-link></v-card-text>
    </v-card>

  </div>
</template>

<script>
import {auth} from '@/firebase.js';
import Errors from '@/components/Errors.vue';

export default {

  components: {
    Errors,
  },

  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      showPassword: false,
      valid: false,
      emailRules: [
        v => !!v || 'Gib eine E-mail-Adresse an.',
        v => /.+@.+/.test(v) || 'Gib eine gültige E-mail-Adresse ein.',
      ],
      authErrors: [],
    };
  },
  
  methods: {
    async login() {
      if (this.valid) {

        try {
          //reset erros
          this.authErrors = [];

          //log in
          const {user} = await auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password);

          //add nessecary infromation for the user in the state
          this.$store.dispatch('addUserInformationToStore', user);

          // change route to whatever is stored in the query (see router index file) or else raumplan
          this.$router.push(this.$route.query.redirect || '/raumplan');

        } catch(error){
          //display error message
          this.authErrors.push({
            text: error.message,
          })
        }
      }
    },
  }
}
</script>

<style>
  /* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>