<template>
<div>
<v-row justify="center">
    <v-date-picker 
    header-color="accent"
    color="primary"
    locale="de-de"
    :first-day-of-week="1"
    v-model="selectedDate"
    ></v-date-picker>
</v-row> 
</div>
</template>

<script>
export default {
    name: 'SelectDate',
    props: ['date'],
    data() {
        return {
          selectedDate: this.date,
        }
    },
    watch: {
        selectedDate: function(value){
            /* changes the date in the parent component */
            this.$emit('changeDate', value);
        }
    }
}
</script>