<template>
<div>
    <v-card
    outlined
    color="secondary"
    class="mx-2 my-n1"
    >
        <v-card-text class="my-0 py-0" style="text-align:center;">
            {{event.duration}} min
        </v-card-text>
    </v-card>

</div>
</template>

<script>
export default {
    name: 'Break',
    props: ['event'],
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style>

</style>