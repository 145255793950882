<template>
<div>

  <v-card max-width="370" class="mx-auto mt-5" flat>
  <v-card flat>
    <v-card-title>
      Raumplan
      <v-spacer></v-spacer>

      <v-btn 
      v-if="this.date != new Date().toISOString().substr(0,10)"
      color="secondary"
      depressed 
      x-small
      @click="redirectToToday"
      class="mx-3"
      >
      Heute
      </v-btn>

      <v-icon color="primary" @click="$router.push('/overview/' + date)">mdi-newspaper-minus</v-icon>

      <v-icon color="primary" @click="redirectToAddEvent">mdi-plus</v-icon>
    </v-card-title>
  </v-card>

  <!-- Date Toolbar -->
  <v-toolbar flat dense class="mt-n3">
      <v-icon @click="previousDay">
        mdi-chevron-left
      </v-icon>
    <v-spacer></v-spacer>
    <v-toolbar-title @click="toggleDatePicker">
      {{ this.date | formatDateWithShortWeekday}}
    </v-toolbar-title>
    <v-spacer></v-spacer>
      <v-icon @click="nextDay">
        mdi-chevron-right
      </v-icon>
  </v-toolbar>

  <SelectDate v-if="displayDatePicker" v-bind:date="date" v-on:changeDate="changeDate" />

  <!-- Raum Toolbar -->
  <v-toolbar flat dense class="mt-n3" >  
    <v-icon @click="previousRoom">
      mdi-chevron-left
    </v-icon>
    <v-spacer></v-spacer>
    <v-toolbar-title @click="toggleRoomPicker">
      {{this.rooms[this.roomIndex].name}}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-icon @click="nextRoom">
      mdi-chevron-right
    </v-icon>
  </v-toolbar>

  <SelectRoom v-if="displayRoomPicker" v-bind:currentRoomIndex="this.roomIndex" v-bind:rooms="this.rooms" v-on:changeRoom="changeRoom" />


  <!-- Online Events -->
  <!-- Placeholder if no online events are there -->
  <v-card 
    flat
    v-if="(!onlineEvents.length) && (rooms[roomIndex].name == 'Online')"
    style="height: 100vh;" 
    v-touch="{
      left: () => nextRoom(),
      right: () => previousRoom(),
    }">
    <v-card-text>
      Noch keine Online Nachhilfestunden heute.
    </v-card-text>
    
  </v-card>

  <!-- Displaying online events -->
  <v-divider v-if="(onlineEvents.length) && (rooms[roomIndex].name == 'Online')"/>
  <div 
  v-for="teacherList in onlineEvents" 
  v-bind:key="teacherList[0].id"
  v-touch="{
    left: () => nextRoom(),
    right: () => previousRoom(),
  }"
  >
    <div 
    v-for="event in teacherList" 
    v-bind:key="event.id"
    >
      <EventDecider v-bind:event="event"/>
    </div> 
    <v-divider/> 
  </div>


  <!-- Not Online Events: -->
  <!-- Events Before the opening time -->
  <div 
    v-for="event in eventsBeforeOpening" 
    v-bind:key="event.id"
    v-touch="{
      left: () => nextRoom(),
      right: () => previousRoom(),
    }">
      <EventDecider v-bind:event="event"/>
  </div>
  <v-divider v-if="eventsBeforeOpening.length" class="my-10"/>

  <!-- Events during the opening hours -->
  <div 
    v-for="event in eventsDuringOpeningHours" 
    v-bind:key="event.id"
    v-touch="{
      left: () => nextRoom(),
      right: () => previousRoom(),
    }">
      <EventDecider v-bind:event="event"/>
  </div>

  <!-- Events after the closing hours -->
  <v-divider v-if="eventsAfterClosing.length" class="my-10" />
  <div 
    v-for="event in eventsAfterClosing" 
    v-bind:key="event.id"
    v-touch="{
      left: () => nextRoom(),
      right: () => previousRoom(),
    }">
      <EventDecider v-bind:event="event"/>
  </div>
</v-card>
</div>
</template>


<script>
import { auth, eventsCollection, teachersCollection } from '@/firebase'
import EventDecider from '@/components/Raumplan/EventDecider'
import SelectDate from '@/components/SelectDate'
import SelectRoom from '@/components/SelectRoom'
import {fillEvents} from '@/assets/js/fillEvents';

import {getInsituteSettings} from '@/assets/js/dataUtils';
import {addDays} from '@/assets/js/timeUtils.js';


export default {
  name: 'Raumplan',

  components: {
    EventDecider,
    SelectDate,
    SelectRoom,
  },

  props: {
    routeDate: { //date got from route
        type: String,
        default: new Date().toISOString().substr(0,10) //today in format 'yyyy-mm-dd'
    },
    routeRoom: { //room got from route
        type: String,
        default: ''
    }
  },

  data() {
    return {
      events: [],
      eventsBeforeOpening: [],
      eventsDuringOpeningHours: [],
      eventsAfterClosing: [],
      onlineEvents: [],
      date: this.routeDate,
      settings: {},
      rooms: [{'name': '', 'id': ''}],
      roomId: this.routeRoom,
      roomIndex: 0,
      displayDatePicker: false,
      displayRoomPicker: false,
      instituteId: '',
      firestoreListener: null, //used to unsubcribe to the snapshot listener bevor destroying the component
    };
  },

  watch: {

    /* If events is changed, the filled in Events will be newly calculated. This could happen eg when an other user changes an event */
    events: function(){
      this.eventsBeforeOpening = [];
      this.eventsDuringOpeningHours = [];
      this.eventsAfterClosing = [];
      this.onlineEvents = [];

      [this.eventsBeforeOpening, this.eventsDuringOpeningHours, this.eventsAfterClosing, this.onlineEvents] 
        = fillEvents(this.events, this.settings, this.date, this.rooms[this.roomIndex]);
    },

    //we need this watchers to also load new events when clicking on "back" in the browser
    routeDate: function() {
      this.date = this.routeDate;
      this.getEvents();
    },

    routeRoom: function() {
      this.roomId = this.routeRoom;
      this.setRoomIndex();
      this.getEvents();
    }
  },

  async created() {
    //Get the institueId of the currently logged in user
    this.instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;

    /* get institute settings and set this.rooms */
    this.settings = await getInsituteSettings();
    this.rooms = this.settings.rooms;

    this.setRoomIndex();
    /* get the events */
    this.getEvents(); //todo von /raumplan auf /raumplan/date/roomindex umleiten?
  },

  beforeDestroy() {
    if (this.firestoreListener) {
      this.firestoreListener(); //unsubscribe from the snapshot listener
    }
  },

  methods: {

    /* Getting and updating necessary Data */

    setRoomIndex(){
      let startRoomIndex =  0;
      for(let i = 0; i < this.rooms.length; i++){
        if (this.rooms[i].id == this.roomId) {
          startRoomIndex = i;
        }
      }
      this.roomIndex = startRoomIndex;
    },

    /**
     * @description loads the events on this.date at this.room.id from fiebase
     */
    getEvents() {

      if(this.rooms[this.roomIndex].name != 'Online') {
        this.firestoreListener = eventsCollection
        .where("instituteId", "==", this.instituteId)
        .where('date', '==', this.date)
        .where('roomId', '==', this.rooms[this.roomIndex].id)
        .orderBy('start')
        .onSnapshot(snapshot => {
          this.events = []
          snapshot.forEach(doc => {
            let event = doc.data()
            event.id = doc.id
            this.events.push(event)
          })
        })
      } else {
        this.firestoreListener = eventsCollection
        .where("instituteId", "==", this.instituteId)
        .where('date', '==', this.date)
        .where('roomId', '==', this.rooms[this.roomIndex].id)
        .orderBy('teacherRef')
        .orderBy('start')
        .onSnapshot(snapshot => {
          this.events = []
          snapshot.forEach(doc => {
            let event = doc.data()
            event.id = doc.id
            this.events.push(event)
          })
        })
      }
    },

    /**
     * @description redirects to the raumplan with this.date and this.roomIndex
     */
    updateEvents() {
      this.eventsBeforeOpening = [];
      this.eventsDuringOpeningHours = [];
      this.eventsAfterClosing = [];
      this.onlineEvents = [];

      this.$router.push({
        name: 'Raumplan', 
        params: {
          routeDate: this.date, 
          routeRoom: this.rooms[this.roomIndex].id
        }
      })
      //then, the events will the updated automatically through the watchers of routeDate and routeRoom
    },

    /* Mutating events to display correctly */


    /* Date: Helper functions for site navigation */
    redirectToToday() {
      this.date = new Date().toISOString().substr(0,10);
      this.updateEvents();
    },

    nextDay() {
      this.date = addDays(this.date, 1);
      this.updateEvents();
    },

    previousDay() {
      this.date = addDays(this.date, -1);
      this.updateEvents();
    },

    /**
     * @description Changes the date of the displayed events to "value"
     * ! Is built to change the date via the component DatePicker
     */
    changeDate(value){
      this.date = value;
      this.displayDatePicker = false;
      this.updateEvents();
    },

    toggleDatePicker(){
      this.displayDatePicker = !this.displayDatePicker;
    },

    /* Room: Helper functions for site navigation */

    nextRoom() {
      let roomsCount = this.rooms.length;
      if ( this.roomIndex < roomsCount - 1 ){
        this.roomIndex += 1;
      } else {
        this.roomIndex = 0;
      }
      this.updateEvents();
    },

    previousRoom() {
      let roomsCount = this.rooms.length;
      if ( this.roomIndex > 0 ){
        this.roomIndex -= 1;
      } else {
        this.roomIndex = roomsCount - 1;
      }
      this.updateEvents();
    },

    toggleRoomPicker(){
      this.displayRoomPicker = !this.displayRoomPicker;
    },

    /**
     * @description Changes the room of the displayed events to "value"
     * ! Is built to change the room via the component RoomPicker
     */
    changeRoom(value){
      this.roomIndex = value;
      this.displayRoomPicker = false;
      this.updateEvents();
    },

    async redirectToAddEvent() {
      /* add date, time and room to store */
      await this.$store.dispatch('resetPrefillAddEvent');
      this.$store.commit('setprefillAddEvent', {key: 'date', value: this.date});
      this.$store.commit('setprefillAddEvent', {key: 'room', value: this.rooms[this.roomIndex]});

      /* redirect to addEvent view */
      this.$router.push('/addevent');
    },
  },

}
</script>

<style lang="scss" scoped>
  
</style>