<template>
<div>
  <v-avatar
    :color="getColor(balance)"
    size="35"
    class="white--text"
  >
  {{balance}}
  </v-avatar>
</div>
</template>

<script>
export default {
  name: 'BalanceLessonAccount',
  props: ['balance'],
  data() {
    return {
      
    }
  },

  methods: {
    getColor (lessons) {
      if (lessons >= 0) return 'success' //green
      else return 'error' //error color
    },
  }
}
</script>