var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto mt-5",attrs:{"max-width":"370","flat":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Raumplan "),_c('v-spacer'),(this.date != new Date().toISOString().substr(0,10))?_c('v-btn',{staticClass:"mx-3",attrs:{"color":"secondary","depressed":"","x-small":""},on:{"click":_vm.redirectToToday}},[_vm._v(" Heute ")]):_vm._e(),_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/overview/' + _vm.date)}}},[_vm._v("mdi-newspaper-minus")]),_c('v-icon',{attrs:{"color":"primary"},on:{"click":_vm.redirectToAddEvent}},[_vm._v("mdi-plus")])],1)],1),_c('v-toolbar',{staticClass:"mt-n3",attrs:{"flat":"","dense":""}},[_c('v-icon',{on:{"click":_vm.previousDay}},[_vm._v(" mdi-chevron-left ")]),_c('v-spacer'),_c('v-toolbar-title',{on:{"click":_vm.toggleDatePicker}},[_vm._v(" "+_vm._s(_vm._f("formatDateWithShortWeekday")(this.date))+" ")]),_c('v-spacer'),_c('v-icon',{on:{"click":_vm.nextDay}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.displayDatePicker)?_c('SelectDate',{attrs:{"date":_vm.date},on:{"changeDate":_vm.changeDate}}):_vm._e(),_c('v-toolbar',{staticClass:"mt-n3",attrs:{"flat":"","dense":""}},[_c('v-icon',{on:{"click":_vm.previousRoom}},[_vm._v(" mdi-chevron-left ")]),_c('v-spacer'),_c('v-toolbar-title',{on:{"click":_vm.toggleRoomPicker}},[_vm._v(" "+_vm._s(this.rooms[this.roomIndex].name)+" ")]),_c('v-spacer'),_c('v-icon',{on:{"click":_vm.nextRoom}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.displayRoomPicker)?_c('SelectRoom',{attrs:{"currentRoomIndex":this.roomIndex,"rooms":this.rooms},on:{"changeRoom":_vm.changeRoom}}):_vm._e(),((!_vm.onlineEvents.length) && (_vm.rooms[_vm.roomIndex].name == 'Online'))?_c('v-card',{directives:[{name:"touch",rawName:"v-touch",value:({
      left: function () { return _vm.nextRoom(); },
      right: function () { return _vm.previousRoom(); },
    }),expression:"{\n      left: () => nextRoom(),\n      right: () => previousRoom(),\n    }"}],staticStyle:{"height":"100vh"},attrs:{"flat":""}},[_c('v-card-text',[_vm._v(" Noch keine Online Nachhilfestunden heute. ")])],1):_vm._e(),((_vm.onlineEvents.length) && (_vm.rooms[_vm.roomIndex].name == 'Online'))?_c('v-divider'):_vm._e(),_vm._l((_vm.onlineEvents),function(teacherList){return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
    left: function () { return _vm.nextRoom(); },
    right: function () { return _vm.previousRoom(); },
  }),expression:"{\n    left: () => nextRoom(),\n    right: () => previousRoom(),\n  }"}],key:teacherList[0].id},[_vm._l((teacherList),function(event){return _c('div',{key:event.id},[_c('EventDecider',{attrs:{"event":event}})],1)}),_c('v-divider')],2)}),_vm._l((_vm.eventsBeforeOpening),function(event){return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
      left: function () { return _vm.nextRoom(); },
      right: function () { return _vm.previousRoom(); },
    }),expression:"{\n      left: () => nextRoom(),\n      right: () => previousRoom(),\n    }"}],key:event.id},[_c('EventDecider',{attrs:{"event":event}})],1)}),(_vm.eventsBeforeOpening.length)?_c('v-divider',{staticClass:"my-10"}):_vm._e(),_vm._l((_vm.eventsDuringOpeningHours),function(event){return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
      left: function () { return _vm.nextRoom(); },
      right: function () { return _vm.previousRoom(); },
    }),expression:"{\n      left: () => nextRoom(),\n      right: () => previousRoom(),\n    }"}],key:event.id},[_c('EventDecider',{attrs:{"event":event}})],1)}),(_vm.eventsAfterClosing.length)?_c('v-divider',{staticClass:"my-10"}):_vm._e(),_vm._l((_vm.eventsAfterClosing),function(event){return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
      left: function () { return _vm.nextRoom(); },
      right: function () { return _vm.previousRoom(); },
    }),expression:"{\n      left: () => nextRoom(),\n      right: () => previousRoom(),\n    }"}],key:event.id},[_c('EventDecider',{attrs:{"event":event}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }