<template>
<div>

  <v-card max-width="370" class="mx-auto mt-5" flat>
    <Errors v-bind:errors="errors"/>

    <v-card-title>
      Nachhilfelehrer:innen
      <v-spacer/>
      <v-icon color="primary" v-if="isAdmin" @click="$router.push('/signup')">mdi-plus</v-icon>
      </v-card-title>

      <!-- search bar -->
      <v-text-field
        v-model.trim="searchInput"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        clearable
        class="mx-4 mb-4"
      ></v-text-field>
    
      <div 
      v-for="teacher in filteredTeachers" 
      v-bind:key="teacher.id"
      >
        <TeacherComp v-bind:teacher="teacher"/>
      </div>
  </v-card>

</div>
</template>


<script>
import { mapState } from 'vuex';
import { teachersCollection } from '@/firebase';
import TeacherComp from '@/components/TeacherComp';
import {auth} from '@/firebase';
import Errors from '@/components/Errors.vue';

export default {
  name: 'Teachers',

  components: {
    TeacherComp,
    Errors,
  },

  data() {
    return {
      teachers: [],
      errors: [],
      searchInput: '',
    }
  },

  computed: {
    ...mapState(['isAdmin']),

    filteredTeachers() {
      return this.teachers.filter(teacher => {
        let isInFirstName = teacher.firstname.toLowerCase().includes(this.searchInput.toLowerCase());
        let isInLastName = teacher.lastname.toLowerCase().includes(this.searchInput.toLowerCase());

        return isInFirstName || isInLastName
      })
    }
  },

  async created() {
    this.getTeachers();
  },

  methods: {
    async getTeachers() {
      //Get the institueId of the currently logged in user
      let instituteId = (await auth.currentUser.getIdTokenResult()).claims.instituteId;
      teachersCollection
        .where('instituteId', '==', instituteId)
        .orderBy('firstname')
        .get()
        .then(snapshot => {
          this.teachers = [];
          snapshot.forEach(doc => {
            let teacher = doc.data();
            teacher.id = doc.id;
            this.teachers.push(teacher);
          })
        }).catch((error) => {
          console.log("Error getting documents: ", error);
          this.errors.push({
            text: 'Die Lehrer:innen konnten nicht aus der Datenbank geladen werden.', 
            type:'firestore',
          })
        });
    },
  }
}
</script>

<style>

</style>