/**
 * @description fills up an array of events with empty events and breaks
 */

import {calculateTimeBetween} from '@/assets/js/timeUtils.js'

var room = {};
var events = [];
var settings = {};
var date = '';

/**
 * @description function to decide how to fill the events. If room.name is Online, events are filled differently than with the normal view
 */
function fillEvents(gottenEvents, gottenSettings, gottenDate, gottenRoom) {
  room = gottenRoom;
  events = gottenEvents;
  settings = gottenSettings;
  date = new Date(gottenDate);

  let eventsBeforeOpening = [];
  let eventsDuringOpeningHours = [];
  let eventsAfterClosing = [];
  let onlineEvents = [];

  if(room.name != 'Online') {
    [eventsBeforeOpening, eventsDuringOpeningHours, eventsAfterClosing] = fillNormalView();
  }
  else if (events.length != 0) {
    /* for online events, the opening hours are not relevant. All events are in the eventsDuringOpeningHours */
    onlineEvents = fillOnlineViewWithDividers();
  }
  else {
    //there are no online events
  }
  
  return [eventsBeforeOpening, eventsDuringOpeningHours, eventsAfterClosing, onlineEvents]
}


/**
 * @description Generates an array 'filledEvents' witch contains all events, but also breaks and empty events.
 * Empty events are added if there is enough time. Eg. the remaining time is grater than minEventDuration (eg 45min; set in insitute settings)
 * Breaks are added if there is less time than minEventDuarationn
 */
function fillNormalView(){
  let endLastEvent = settings.opening;
  let minEventDuration = settings.minEventDuration;
  let currentStart = settings.opening; //needes to be calculated to display a start time for empty events. Is increased to the new values before a new event is added.
  let currentEnd = settings.opening;  //needes to be calculated to display an end time for empty events. Is increased to the new values before a new event is added.

  let eventsBeforeOpening = [];
  let filledEvents = []; //for the events during opening hours
  let eventsAfterClosing = [];
  
  /* loop throgh all events */
  for (let i=0; i < events.length; i++){
    var event = events[i];
    var timeBetween = calculateTimeBetween(endLastEvent, event.start);

    /* if the end of the event event is before the opening time. add it to eventsBeforeOpening, without filling up with empty events */
    if(calculateTimeBetween(endLastEvent, event.end) < 0){
      eventsBeforeOpening.push(event);  //add the event to filledEvents
    
    /* if the event is in between opening and closing time, fill up with empty events and breaks */
    } else if (calculateTimeBetween(event.start, settings.closing) > 0){

      /* Add empty events as long as the remaining time is > minEventDuration */
      while (timeBetween >= minEventDuration) {
        /* add an empty event to filledEvents and update start and end time  */
        [filledEvents, currentStart, currentEnd] = addEmptyEventToFilledEvents(filledEvents, currentEnd); 
        timeBetween -= minEventDuration;
      }
      
      /* if there is still timeBetween left, add it as a pause. Is always less then minEventDuration */
      if (timeBetween > 0){
        /* add a pause to filledEvents and update the current start and end time  */
        [filledEvents, currentStart, currentEnd] = addPauseToFilledEvents(filledEvents, currentEnd, timeBetween);
      }
      /* add the event to filledEvents */
      currentStart = event.start; //update the start time
      currentEnd = event.end; //update the end time
      filledEvents.push(event);  //add the event to filledEvents
      endLastEvent = event.end;

    /* if the event is after closing time, collect it in the array eventsAfterClosing */
    } else {
      eventsAfterClosing.push(event);
    }
  }


  /* check the time after the last event and before the closing time */
  timeBetween = calculateTimeBetween(endLastEvent, settings.closing);
   /* Add empty events as long as the remaining time is > minEventDuration */
  while (timeBetween >= minEventDuration) {
    /* add an empty event to filledEvents and update start and end time */
    [filledEvents, currentStart, currentEnd] = addEmptyEventToFilledEvents(filledEvents, currentEnd);
    timeBetween -= minEventDuration;
  }
  /* if there is still timeBetween left, add it as a pause. Is always less then minEventDuration */
  if (timeBetween > 0){
    /* add a pause to filledEvents and update the current start and end time */
    [filledEvents, currentStart, currentEnd] = addPauseToFilledEvents(filledEvents, currentEnd, timeBetween);
  }

  return [eventsBeforeOpening, filledEvents, eventsAfterClosing]
}

/**
 * @description Adds dividers between the different teachers in the "Online" view
 */
function fillOnlineViewWithDividers(){
  let onlineEvents = [];
  let lastTeacherId = events[0].teacherRef.id;
  let eventsArrayWithTheSameTeacher = [];

    for(let i=0; i < events.length; i++){
      let currentTeacherId = events[i].teacherRef.id;
      if(lastTeacherId != currentTeacherId){
        onlineEvents.push(eventsArrayWithTheSameTeacher);
        eventsArrayWithTheSameTeacher = [];
      }
      lastTeacherId = currentTeacherId;
      eventsArrayWithTheSameTeacher.push(events[i]);
    }
    onlineEvents.push(eventsArrayWithTheSameTeacher);

  return onlineEvents
}

/**
* @description Adds a empty event to filledEvents and updates the starting and end times
* @param oldEnd: String of format 'hh:mm': End time of the last event
* @returns Array which contains the new start and end times, after the newly added empty event
*/
function addEmptyEventToFilledEvents(filledEvents, oldEnd){
  var start = oldEnd; //update the start time
  var end = addTime(settings.minEventDuration, start); //update the end time
  filledEvents.push({
    type: 'empty',
    start: start,
    end: end,
    date: date.toISOString().substr(0,10),
    room: room.name,
    roomId: room.id,
  });
  return [filledEvents, start, end];
}

/**
* @description Adds a pause-event to filledEvents and updates the starting and end times
* @param oldEnd: String of format 'hh:mm': End time of the last event
* @param duration: Int. Duration of the pause-event
* @returns Array which contains the new start and end times, after the newly added pause-event
*/
function addPauseToFilledEvents(filledEvents, oldEnd, timeBetween){
  var start = oldEnd; //update the start time
  var end = addTime(timeBetween, start); //update the end time
  filledEvents.push({
    type: 'pause',
    duration: timeBetween,
  });
  return [filledEvents, start, end];
}


/* Helper functions for site navigation */

/**
 * @description adds a given amount of minutes to a given time
 * @param minutesToBeAdded: Int. Amount of minutes that should be added to the parameter time
 * @param time: String of format 'hh:mm'. Gives the time where the minutes should be added
 * @returns String of format 'hh:mm'. Time after the given minutes were added to the given time
 */
function addTime(minutesToBeAdded, time){
  var oldDate = new Date('2020-12-24T' + time + ':00Z');
  var newDate = new Date(oldDate.getTime() + minutesToBeAdded * 60000);
  if (oldDate.toISOString().substring(0,10) != newDate.toISOString().substring(0,10)){
    throw "If you add " + minutesToBeAdded + "min to " + time + ", the time will be on the next day. This is not allowed."
  }
  return newDate.toISOString().substring(11, 16);
}


export {fillEvents}